import request from '../request'

export function getSearchEngines (params) {
    return request({
        url: `/console/search`,
        method: 'GET',
        params
    })
}

export function reloadSearchEngine (id) {
    return request({
        url: `/console/search/${id}`,
        method: 'PUT'
    })
}

export function clearSearchEngine (id) {
    return request({
        url: `/console/search/${id}`,
        method: 'DELETE'
    })
}