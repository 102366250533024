<template>
    <div class="p-t-20 p-r-20 p-l-20 p-b-20">
        <el-card body-style="padding: 10px 20px">
            <h3>
                搜索引擎管理
            </h3>
        </el-card>
        <el-row :gutter="20">
            <el-col :span="12" v-for="engine in engines">
                <el-card body-style="padding: 10px 20px;" class="m-t-20" v-loading="engine.loading">
                    <h3 class="flex align-items-center justify-content-between">
                        {{engine.label}}
                        <i :class="statusIcon[engine.status]"> {{engine.status}}</i>
                    </h3>
                    <div>
                        <el-button type="text" class="text-danger" @click="clearIndex(engine)">
                            <i class="el-icon-delete"></i>
                            清空
                        </el-button>
                        <el-button type="text" @click="reloadIndex(engine)">
                            <i class="el-icon-files"></i>
                            重建索引
                        </el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>

import {
    clearSearchEngine,
    getSearchEngines,
    reloadSearchEngine
} from "@/assets/javascripts/api/console/search";

export default {
    name: "SearchEnginePage",
    data () {
        return {
            statusIcon: {
                running: "text-success el-icon-check",
                indexing: "text-primary el-icon-loading",
                reset: "text-danger el-icon-warning"
            },
            engines: []
        }
    },
    methods: {
        actionFinished(refresh, dialog) {
            this.modal_visibility[dialog] = false;
            if (refresh) {
                this.getSearchEngines()
            }
        },
        getSearchEngines () {
            getSearchEngines().then(result => {
                this.engines = result.data.map(item => {
                    item.loading = false
                    return item
                })
            })
        },
        clearIndex (engine) {
            this.$confirm('被删除信息将无法恢复，是否确认？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                engine.loading = true
                clearSearchEngine(engine.id).then(result => {
                    this.$message({
                        type: 'success',
                        message: '清空成功',
                        showClose: true
                    })
                    engine.loading = false
                    this.getSearchEngines()
                }).catch(err => {
                    engine.loading = false
                })
            })
        },
        reloadIndex (engine) {
            this.$confirm('被删除信息将无法恢复，是否确认？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                engine.loading = true
                reloadSearchEngine(engine.id).then(result => {
                    this.$message({
                        type: 'success',
                        message: '重建成功',
                        showClose: true
                    })
                    engine.loading = false
                    this.getSearchEngines()
                }).catch(err => {
                    engine.loading = false
                })
            })
        }
    },
    created() {
        this.getSearchEngines()
    }
}
</script>

<style scoped>

</style>